import { render, staticRenderFns } from "./ManageList.vue?vue&type=template&id=6700dad3&scoped=true"
import script from "./ManageList.vue?vue&type=script&lang=ts"
export * from "./ManageList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6700dad3",
  null
  
)

export default component.exports